.profile-main{
    display: inline-block;
    margin: 8px 0;
    min-width: 45%;
    padding: 16px;
    border-radius: 10px;   
    margin-right:10%;
}
.profile-image-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.image-container{
    height: 35px; 
    width: 35px; 
    align-self: center;
}
.lable-section{
    background-color: transparent;
     margin-left: 10px;
}
.logdata-label{
    font-size: 13px;
    font-weight: bold;
    color: #3e4f65;
    font-family: Gordita;
}
.logdata-value{
    width: 90%;
    font-size: 18px;
    color: #192a46;
    font-weight: bold;
    font-family: Gordita;
}
@media screen and (max-width: 450px){
    .profile-main{
        display: inline-block;
        margin: 8px 0;
        min-width: 45%;
        padding: 16px;
        border-radius: 10px;   
        margin-right:5%;
        padding-top: 15px;
    }
    .image-container {
        height: 22px;
        width: 22px;
        align-self: center;
    }
    .logdata-label {
        font-size: 11px;
        font-weight: bold;
        color: #3e4f65;
        font-family: Gordita;
    }
    .logdata-value {
        width: 90%;
        font-size: 15px;
        color: #192a46;
        font-weight: bold;
        font-family: Gordita;
    }
    .profile-image-container {
        display: flex;
        flex-wrap: inherit;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
}
