ul {
  list-style: none;
}

li::before {
  content: "*";
  margin-left: 0;
  color: #3279e7;
  margin-top: 10px;
  margin-right: 10px;
}
.main-content{
  overflow-x: auto;
  display: flex;
  flex-direction: "row";
}
.content-header{
  width: 90%;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
  display: inline-block;
  background-color: #eef0f1;
}
.content-item{
  font-size: 20px;
  color: #192a46;
  font-family: Gordita;
  word-wrap: break-word;
}
.content-overview{
  width: 376px;
  height: 100px;
}
.contenr-item-overviwe{
  color:#6f7b8f;
  font-size: 15px;
  margin-top: 15px;
  font-family: Gordita;
  word-wrap: break-word;
  text-overflow: ellipsis;
}
.content-description{
  display: flex;
  margin-top: 15px;
  justify-content: flex-start;
  width: 80px;
  height: 60px;
}
.content-text{
  margin: 5px;
  color: #192a46;
  font-size: 18px;
  border-radius: 20px;
  padding: 10px 15px;
  font-family: Gordita;
  background-color: #fff;
}
.content-types{
  width: 100%;
  opacity: 1;
  height: 1px;
  margin: 20px 0;
  background-color: #d9e0eb;
}
.stride-match-title{
  opacity: 0.7;
  color: #192a46;
  font-size: 20px;
  margin-top: 15px;
  font-weight: bold;
  font-family: Gordita;
}
.stride-match-score{
  margin-top: 15px;
   font-family: Gordita;
}
.tooltip {
  position: relative;
  /* cursor: pointer; */
}

/* Tooltip box */
.tooltip:before {
  content: attr(data-tooltip); 
  position: absolute;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 8px;
  line-height: 1.2;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity .6s;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  font-size: 12px;
  visibility: hidden;
}

/* Tooltip arrow */
.tooltip:after {
  content: "";
  position: absolute;
  bottom: 75%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  opacity: 0;
  transition: opacity .6s;
  border-color: #062B45 transparent transparent transparent;
  visibility: hidden;
}

.tooltip:hover:before, .tooltip:hover:after {
  opacity: 1;
  visibility: visible;
}
@media screen and (max-width: 450px){

  .content-overview{
    width: 259px;
    height: 150px;
  }

}